var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticClass:"swap-form-main-box-card",attrs:{"shadow":"always"}},[_c('span',{staticClass:"swap-header",staticStyle:{"color":"var(--primary-text)"}},[_vm._v(" SWAP ")]),_c('div',{staticClass:"from-section"},[_c('span',[_vm._v(" From")]),(_vm.shouldShowBalance())?_c('span',[_vm._v("Balance: "+_vm._s(_vm.formatDecimals( _vm.getBalanceOfSelectedToken(_vm.getSwapForm.inputToken), _vm.getSwapForm.inputToken ))+" ")]):_vm._e()]),(_vm.tokenList.length > 0)?_c('div',{class:`asset-selection ${_vm.buttonDisabled ? 'error' : ''}`},[_c('TokenSelectMenu',{attrs:{"id":'tokenInput',"list":_vm.tokenList,"on-change":(e) => _vm.handleInputChange(e),"amount":_vm.getSwapForm.inputAmount,"selected-token":_vm.getSwapForm.inputToken}}),(_vm.getSwapForm.inputToken)?_c('div',{staticClass:"token-usd-value"},[_vm._v(" ~ $"+_vm._s(_vm.getInputUsdValue())+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticStyle:{"display":"flex","width":"100%","height":"30px"}},[_c('div',{staticClass:"percent-input-buttons"},[_c('div',{staticClass:"error-message-section"},[_vm._v(_vm._s(_vm.errorMessage))]),(
          _vm.shouldShowBalance() &&
          _vm.getBalanceOfSelectedToken(_vm.getSwapForm.inputToken) > 0
        )?_c('div',{staticClass:"percent-input-button-section"},_vm._l((_vm.percentOptions),function(option){return _c('el-button',{key:option,staticClass:"percent-input-option",attrs:{"type":"text"},on:{"click":function($event){return _vm.applyOption(option)}}},[_vm._v(" "+_vm._s(option === 100 ? "MAX" : `${option}%`)+" ")])}),1):_vm._e()])]),_c('div',{staticClass:"swap-placeholder"},[_c('div',{staticClass:"swap-image-container"},[_c('el-button',{staticStyle:{"font-size":"20px","padding":"0","margin":"0"},attrs:{"type":"text","icon":"fak fa-crunchy-swap fa-rotate-90"},on:{"click":_vm.reverseSwap}})],1)]),_c('div',{staticClass:"from-section"},[_c('span',[_vm._v(" To(estimate)")]),(_vm.shouldShowBalance())?_c('span',[_vm._v(" Balance: "+_vm._s(_vm.formatDecimals( _vm.getBalanceOfSelectedToken(_vm.getSwapForm.outputToken), _vm.getSwapForm.outputToken )))]):_vm._e()]),_c('div',{staticClass:"asset-selection"},[_c('TokenSelectMenu',{attrs:{"id":'tokenOutput',"list":_vm.tokenList,"on-change":_vm.handleOutputChange,"amount":_vm.formatDecimals(_vm.getCurrentTrade.outputAmount, _vm.getSwapForm.outputToken),"input-disabled":true,"selected-token":_vm.getSwapForm.outputToken || {},"is-loading":_vm.isCalculatingBestRoute}})],1),_c('div',{staticStyle:{"width":"100%","margin-top":"16px","text-align":"center"}},[_c('div',{style:(`${!_vm.getPkh ? 'display: none;' : ''}`)},[_c('el-button',{style:({
          border: 'none',
          'border-radius': '20px',
          width: '100%',
          'max-width': '100%',
          margin: 'auto',
          'font-weight': '500',
          padding: '16px 20px',
        }),attrs:{"disabled":_vm.isLoading ||
          _vm.formSubmitting ||
          _vm.isGettingBalance ||
          _vm.isCalculatingBestRoute ||
          _vm.buttonDisabled,"type":"primary"},on:{"click":_vm.onSubmit}},[(
            _vm.isLoading ||
            _vm.formSubmitting ||
            _vm.isGettingBalance ||
            _vm.isCalculatingBestRoute
          )?_c('i',{staticClass:"el-icon-loading",style:({ color: '#ffffff' })}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.getStatusText()))])])],1),_c('div',{style:(`${_vm.getPkh ? 'display: none;' : ''}`)},[_c('connect-button')],1)]),(_vm.areApisLoading)?_c('div',{staticClass:"tooltip",staticStyle:{"height":"20px","width":"20px","position":"absolute","right":"8px","bottom":"8px"},attrs:{"data-html":"true"}},[_c('i',{staticClass:"el-icon-loading"}),_c('div',{staticClass:"tooltiptext",attrs:{"data-html":"true"}},[_c('span',[_vm._v("Loading Data")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }