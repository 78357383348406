<template>
  <el-card class="box-card" shadow="always" style="width: 100%">
    <div style="margin: 40px">
      <h1>Initial Farm Offering (IFO)</h1>
      <p>
        Launchpad offering participation in initial token distributions from the
        best upcoming Tezos based projects.
      </p>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "DaasCard",
};
</script>

<style lang="scss" scoped>
p {
  font-size: 16px;
  color: var(--color-subheading-text);
}
.el-button {
  font-weight: bold;
}
</style>
