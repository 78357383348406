import { render, staticRenderFns } from "./NftWalletView.vue?vue&type=template&id=2189c2db"
import script from "./NftWalletView.vue?vue&type=script&lang=js"
export * from "./NftWalletView.vue?vue&type=script&lang=js"
import style0 from "./NftWalletView.vue?vue&type=style&index=0&id=2189c2db&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports