import { render, staticRenderFns } from "./FarmCreate.vue?vue&type=template&id=4b104d80&scoped=true"
import script from "./FarmCreate.vue?vue&type=script&lang=js"
export * from "./FarmCreate.vue?vue&type=script&lang=js"
import style0 from "./FarmCreate.vue?vue&type=style&index=0&id=4b104d80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b104d80",
  null
  
)

export default component.exports