var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"stake-dialog",attrs:{"title":"Stake Tokens","visible":_vm.form.visible,"width":"380px"},on:{"update:visible":function($event){return _vm.$set(_vm.form, "visible", $event)}}},[(_vm.form.farm.poolToken.isQuipuLp)?_c('p',{staticClass:"stake-infor"},[_vm._v(" Stake "+_vm._s(_vm.form.farm.poolToken.symbol)+" LP tokens to earn "+_vm._s(_vm.form.farm.rewardToken.symbol)+". ")]):_c('p',{staticClass:"stake-infor"},[_vm._v(" Stake "+_vm._s(_vm.form.farm.poolToken.symbol)+" to earn "+_vm._s(_vm.form.farm.rewardToken.symbol)+". ")]),_c('el-form',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.form.loading),expression:"form.loading",modifiers:{"lock":true}}],ref:"form",attrs:{"model":_vm.form,"label-position":"top","hide-required-asterisk":""}},[_c('div',{staticClass:"current-balance _info-card",staticStyle:{"border-radius":"22px","background":"var(--background-information-card)","padding":"12px 20px","margin-bottom":"18px"}},[_c('el-row',{attrs:{"type":"flex","align":"middle","justify":"space-between"}},[_c('el-col',{staticClass:"_info-card__title",staticStyle:{"font-size":"12px"},attrs:{"span":8}},[_vm._v("BALANCE")]),(
            _vm.form.farm.poolToken.balance >= 0.0001 ||
            !_vm.form.farm.poolToken.balance
          )?_c('el-col',{staticStyle:{"font-weight":"bold","text-align":"right"},attrs:{"span":16}},[_vm._v(_vm._s(_vm.vueNumberFormat(_vm.form.farm.poolToken.balance)))]):(_vm.form.farm.poolToken.balance >= 0.000001)?_c('el-col',{staticStyle:{"font-weight":"bold","text-align":"right"},attrs:{"span":16}},[_vm._v(_vm._s(_vm.vueNumberFormat(_vm.form.farm.poolToken.balance, { precision: 6 })))]):(_vm.form.farm.poolToken.balance >= 0.00000001)?_c('el-col',{staticStyle:{"font-weight":"bold","text-align":"right"},attrs:{"span":16}},[_vm._v(_vm._s(_vm.vueNumberFormat(_vm.form.farm.poolToken.balance, { precision: 8 })))]):(_vm.form.farm.poolToken.balance >= 0.000000000001)?_c('el-col',{staticStyle:{"font-weight":"bold","text-align":"right"},attrs:{"span":16}},[_vm._v(_vm._s(_vm.vueNumberFormat(_vm.form.farm.poolToken.balance, { precision: 12 })))]):_c('el-col',{staticStyle:{"font-weight":"bold","text-align":"right"},attrs:{"span":16}},[_vm._v(_vm._s(_vm.vueNumberFormat(_vm.form.farm.poolToken.balance, { precision: 18 })))])],1)],1),(_vm.form.farm.poolToken.isPlentyLp)?_c('el-form-item',{staticStyle:{"margin-bottom":"14px"},attrs:{"label":"Stake Tokens","prop":"input","rules":[
        {
          type: 'number',
          required: true,
          message: 'Enter an amount',
          transform: (v) => Number(v),
        },
        {
          type: 'number',
          min: 0.000000000001,
          message: 'Enter a valid amount (at least 0.000000000001)',
          transform: (v) => Number(v),
        },
      ]}},[_c('el-input',{attrs:{"label":"Stake Tokens"},model:{value:(_vm.form.input),callback:function ($$v) {_vm.$set(_vm.form, "input", $$v)},expression:"form.input"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v(_vm._s(_vm.form.farm.poolToken.symbol))])])],1):(_vm.form.farm.poolToken.isSpicyLp)?_c('el-form-item',{staticStyle:{"margin-bottom":"14px"},attrs:{"label":"Stake Tokens","prop":"input","rules":[
        {
          type: 'number',
          required: true,
          message: 'Enter an amount',
          transform: (v) => Number(v),
        },
        {
          type: 'number',
          min: 0.000000000000000001,
          message: 'Enter a valid amount (at least 0.000000000000000001)',
          transform: (v) => Number(v),
        },
      ]}},[_c('el-input',{attrs:{"label":"Stake Tokens"},model:{value:(_vm.form.input),callback:function ($$v) {_vm.$set(_vm.form, "input", $$v)},expression:"form.input"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v(_vm._s(_vm.form.farm.poolToken.symbol))])])],1):_c('el-form-item',{staticStyle:{"margin-bottom":"14px"},attrs:{"label":"Stake Tokens","prop":"input","rules":[
        {
          type: 'number',
          required: true,
          message: 'Enter an amount',
          transform: (v) => Number(v),
        },
        {
          type: 'number',
          min: 0.000001,
          message: 'Enter a valid amount (at least 0.000001)',
          transform: (v) => Number(v),
        },
      ]}},[_c('el-input',{attrs:{"label":"Stake Tokens"},model:{value:(_vm.form.input),callback:function ($$v) {_vm.$set(_vm.form, "input", $$v)},expression:"form.input"}},[(_vm.form.farm.poolToken.isQuipuLp)?_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v(_vm._s(_vm.form.farm.poolToken.symbol)+" LP")]):_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v(_vm._s(_vm.form.farm.poolToken.symbol))])])],1),_c('el-button',{staticStyle:{"margin-top":"8px","margin-bottom":"22px"},attrs:{"type":"success","size":"small","round":""},on:{"click":_vm.setMaxInput}},[_vm._v("USE MAX")]),_c('div',{staticClass:"stake-warning",staticStyle:{"word-break":"auto-phrase"}},[_c('span',{staticClass:"stake-warning__content"},[_vm._v("Unclaimed deposits and rewards are subject to a 0.55% fee per day, beginning 6 months after a farm completes.")])]),_c('el-button',{staticStyle:{"border-radius":"20px","font-weight":"bold","width":"100%","padding":"6px 16px 6px 16px","margin-left":"0","height":"40px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.stakeFarm(_vm.form.farm.id)}}},[_vm._v("Stake")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }